@import "https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap";
*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: Open Sans, ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.pointer-events-none {
  pointer-events: none;
}

.invisible {
  visibility: hidden;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.-bottom-4 {
  bottom: -1rem;
}

.-top-2 {
  top: -.5rem;
}

.-top-2\.5 {
  top: -.625rem;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.left-\[50px\] {
  left: 50px;
}

.right-0 {
  right: 0;
}

.right-0\.5 {
  right: .125rem;
}

.right-\[26px\] {
  right: 26px;
}

.top-0 {
  top: 0;
}

.top-1 {
  top: .25rem;
}

.isolate {
  isolation: isolate;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.col-span-3 {
  grid-column: span 3 / span 3;
}

.row-span-2 {
  grid-row: span 2 / span 2;
}

.row-span-3 {
  grid-row: span 3 / span 3;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-10 {
  height: 2.5rem;
}

.h-2 {
  height: .5rem;
}

.h-4 {
  height: 1rem;
}

.h-5 {
  height: 1.25rem;
}

.h-7 {
  height: 1.75rem;
}

.h-8 {
  height: 2rem;
}

.h-9 {
  height: 2.25rem;
}

.h-\[--visual-viewport-height\] {
  height: var(--visual-viewport-height);
}

.h-dvh {
  height: 100dvh;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.max-h-60 {
  max-height: 15rem;
}

.max-h-\[90vh\] {
  max-height: 90vh;
}

.max-h-\[inherit\] {
  max-height: inherit;
}

.max-h-full {
  max-height: 100%;
}

.min-h-48 {
  min-height: 12rem;
}

.min-h-64 {
  min-height: 16rem;
}

.min-h-96 {
  min-height: 24rem;
}

.min-h-\[600px\] {
  min-height: 600px;
}

.w-11 {
  width: 2.75rem;
}

.w-2 {
  width: .5rem;
}

.w-20 {
  width: 5rem;
}

.w-32 {
  width: 8rem;
}

.w-4 {
  width: 1rem;
}

.w-5 {
  width: 1.25rem;
}

.w-7 {
  width: 1.75rem;
}

.w-8 {
  width: 2rem;
}

.w-96 {
  width: 24rem;
}

.w-\[var\(--trigger-width\)\] {
  width: var(--trigger-width);
}

.w-full {
  width: 100%;
}

.w-max {
  width: max-content;
}

.w-screen {
  width: 100vw;
}

.min-w-20 {
  min-width: 5rem;
}

.min-w-48 {
  min-width: 12rem;
}

.min-w-\[420px\] {
  min-width: 420px;
}

.min-w-\[960px\] {
  min-width: 960px;
}

.max-w-\[80vw\] {
  max-width: 80vw;
}

.flex-1 {
  flex: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.-rotate-90 {
  --tw-rotate: -90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-0 {
  --tw-rotate: 0deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes ping {
  75%, 100% {
    opacity: 0;
    transform: scale(2);
  }
}

.animate-ping {
  animation: 1s cubic-bezier(0, 0, .2, 1) infinite ping;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: 1s linear infinite spin;
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.select-none {
  -webkit-user-select: none;
  user-select: none;
}

.resize-none {
  resize: none;
}

.grid-flow-row {
  grid-auto-flow: row;
}

.grid-flow-col {
  grid-auto-flow: column;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-cols-\[240px_1fr\] {
  grid-template-columns: 240px 1fr;
}

.grid-rows-\[1fr\] {
  grid-template-rows: 1fr;
}

.grid-rows-\[30px_1fr\] {
  grid-template-rows: 30px 1fr;
}

.grid-rows-\[46px_1fr\] {
  grid-template-rows: 46px 1fr;
}

.grid-rows-\[min-content_min-content\] {
  grid-template-rows: min-content min-content;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-0 {
  gap: 0;
}

.gap-0\.5 {
  gap: .125rem;
}

.gap-1 {
  gap: .25rem;
}

.gap-1\.5 {
  gap: .375rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-20 {
  gap: 5rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-x-8 {
  column-gap: 2rem;
}

.self-end {
  align-self: flex-end;
}

.self-center {
  align-self: center;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.rounded {
  border-radius: .25rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-none {
  border-radius: 0;
}

.rounded-sm {
  border-radius: .125rem;
}

.rounded-b-lg {
  border-bottom-right-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

.rounded-t-lg {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}

.border {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-7 {
  border-width: 7px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-b-3 {
  border-bottom-width: 3px;
}

.border-r {
  border-right-width: 1px;
}

.border-t {
  border-top-width: 1px;
}

.border-t-0 {
  border-top-width: 0;
}

.border-black\/10 {
  border-color: #0000001a;
}

.border-black\/5 {
  border-color: #0000000d;
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity));
}

.border-red-600 {
  --tw-border-opacity: 1;
  border-color: rgb(220 38 38 / var(--tw-border-opacity));
}

.border-slate-200 {
  --tw-border-opacity: 1;
  border-color: rgb(226 232 240 / var(--tw-border-opacity));
}

.border-slate-300 {
  --tw-border-opacity: 1;
  border-color: rgb(203 213 225 / var(--tw-border-opacity));
}

.border-slate-400 {
  --tw-border-opacity: 1;
  border-color: rgb(148 163 184 / var(--tw-border-opacity));
}

.border-slate-400\/60 {
  border-color: #94a3b899;
}

.border-theme-400 {
  --tw-border-opacity: 1;
  border-color: rgb(77 180 178 / var(--tw-border-opacity));
}

.border-theme-500 {
  --tw-border-opacity: 1;
  border-color: rgb(52 153 153 / var(--tw-border-opacity));
}

.border-transparent {
  border-color: #0000;
}

.border-r-gray-200 {
  --tw-border-opacity: 1;
  border-right-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.bg-black\/\[35\%\] {
  background-color: #00000059;
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.bg-gray-100\/60 {
  background-color: #f3f4f699;
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.bg-gray-400\/10 {
  background-color: #9ca3af1a;
}

.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.bg-orange-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(234 88 12 / var(--tw-bg-opacity));
}

.bg-red-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(248 113 113 / var(--tw-bg-opacity));
}

.bg-red-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity));
}

.bg-slate-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity));
}

.bg-slate-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(226 232 240 / var(--tw-bg-opacity));
}

.bg-slate-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(203 213 225 / var(--tw-bg-opacity));
}

.bg-slate-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(248 250 252 / var(--tw-bg-opacity));
}

.bg-slate-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(71 85 105 / var(--tw-bg-opacity));
}

.bg-theme-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(213 242 239 / var(--tw-bg-opacity));
}

.bg-theme-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(77 180 178 / var(--tw-bg-opacity));
}

.bg-theme-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(52 153 153 / var(--tw-bg-opacity));
}

.bg-theme-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(39 120 122 / var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: #0000;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-clip-padding {
  background-clip: padding-box;
}

.stroke-red-800 {
  stroke: #991b1b;
}

.stroke-slate-500 {
  stroke: #64748b;
}

.stroke-theme-400 {
  stroke: #4db4b2;
}

.p-1 {
  padding: .25rem;
}

.p-1\.5 {
  padding: .375rem;
}

.p-2 {
  padding: .5rem;
}

.p-2\.5 {
  padding: .625rem;
}

.p-3 {
  padding: .75rem;
}

.p-4 {
  padding: 1rem;
}

.p-8 {
  padding: 2rem;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-2\.5 {
  padding-left: .625rem;
  padding-right: .625rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-9 {
  padding-bottom: 2.25rem;
}

.pl-0 {
  padding-left: 0;
}

.pl-0\.5 {
  padding-left: .125rem;
}

.pl-3 {
  padding-left: .75rem;
}

.pr-1 {
  padding-right: .25rem;
}

.pr-10 {
  padding-right: 2.5rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pr-\[100px\] {
  padding-right: 100px;
}

.pt-3 {
  padding-top: .75rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.align-middle {
  vertical-align: middle;
}

.font-sans {
  font-family: Open Sans, ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.tracking-wide {
  letter-spacing: .025em;
}

.tracking-wider {
  letter-spacing: .05em;
}

.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}

.text-red-600 {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity));
}

.text-slate-300 {
  --tw-text-opacity: 1;
  color: rgb(203 213 225 / var(--tw-text-opacity));
}

.text-slate-600 {
  --tw-text-opacity: 1;
  color: rgb(71 85 105 / var(--tw-text-opacity));
}

.text-slate-700 {
  --tw-text-opacity: 1;
  color: rgb(51 65 85 / var(--tw-text-opacity));
}

.text-slate-800 {
  --tw-text-opacity: 1;
  color: rgb(30 41 59 / var(--tw-text-opacity));
}

.text-theme-400 {
  --tw-text-opacity: 1;
  color: rgb(77 180 178 / var(--tw-text-opacity));
}

.text-theme-500 {
  --tw-text-opacity: 1;
  color: rgb(52 153 153 / var(--tw-text-opacity));
}

.text-theme-600 {
  --tw-text-opacity: 1;
  color: rgb(39 120 122 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-zinc-900 {
  --tw-text-opacity: 1;
  color: rgb(24 24 27 / var(--tw-text-opacity));
}

.opacity-75 {
  opacity: .75;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-2xl {
  --tw-shadow: 0 25px 50px -12px #00000040;
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline-none {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.outline {
  outline-style: solid;
}

.outline-0 {
  outline-width: 0;
}

.outline-2 {
  outline-width: 2px;
}

.-outline-offset-2 {
  outline-offset: -2px;
}

.-outline-offset-4 {
  outline-offset: -4px;
}

.outline-offset-2 {
  outline-offset: 2px;
}

.outline-blue-600 {
  outline-color: #2563eb;
}

.outline-white {
  outline-color: #fff;
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.backdrop-blur-none {
  --tw-backdrop-blur: blur(0);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-blur-xxs {
  --tw-backdrop-blur: blur(1px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-100 {
  transition-duration: .1s;
}

.duration-200 {
  transition-duration: .2s;
}

.duration-300 {
  transition-duration: .3s;
}

.duration-500 {
  transition-duration: .5s;
}

.ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.will-change-transform {
  will-change: transform;
}

@keyframes enter {
  from {
    opacity: var(--tw-enter-opacity, 1);
    transform: translate3d(var(--tw-enter-translate-x, 0), var(--tw-enter-translate-y, 0), 0) scale3d(var(--tw-enter-scale, 1), var(--tw-enter-scale, 1), var(--tw-enter-scale, 1)) rotate(var(--tw-enter-rotate, 0));
  }
}

@keyframes exit {
  to {
    opacity: var(--tw-exit-opacity, 1);
    transform: translate3d(var(--tw-exit-translate-x, 0), var(--tw-exit-translate-y, 0), 0) scale3d(var(--tw-exit-scale, 1), var(--tw-exit-scale, 1), var(--tw-exit-scale, 1)) rotate(var(--tw-exit-rotate, 0));
  }
}

.animate-in {
  --tw-enter-opacity: initial;
  --tw-enter-scale: initial;
  --tw-enter-rotate: initial;
  --tw-enter-translate-x: initial;
  --tw-enter-translate-y: initial;
  animation-name: enter;
  animation-duration: .15s;
}

.animate-out {
  --tw-exit-opacity: initial;
  --tw-exit-scale: initial;
  --tw-exit-rotate: initial;
  --tw-exit-translate-x: initial;
  --tw-exit-translate-y: initial;
  animation-name: exit;
  animation-duration: .15s;
}

.fade-in {
  --tw-enter-opacity: 0;
}

.fade-out {
  --tw-exit-opacity: 0;
}

.zoom-in-105 {
  --tw-enter-scale: 1.05;
}

.zoom-out-95 {
  --tw-exit-scale: .95;
}

.duration-100 {
  animation-duration: .1s;
}

.duration-200 {
  animation-duration: .2s;
}

.duration-300 {
  animation-duration: .3s;
}

.duration-500 {
  animation-duration: .5s;
}

.ease-in {
  animation-timing-function: cubic-bezier(.4, 0, 1, 1);
}

.ease-out {
  animation-timing-function: cubic-bezier(0, 0, .2, 1);
}

:root {
  --theme-300: #abe4df;
  --theme-500: #349999;
}

.theme-scrollbar::-webkit-scrollbar {
  width: 12px;
}

.theme-scrollbar::-webkit-scrollbar-track {
  background-color: #f4f4f4;
  border: 1px solid #eee;
  border-radius: 6px;
}

.theme-scrollbar::-webkit-scrollbar-thumb {
  background-clip: content-box;
  background-color: var(--theme-300);
  border: 3px solid #0000;
  border-radius: 8px;
}

.first\:border-l-0:first-child, .last\:border-l-0:last-child {
  border-left-width: 0;
}

.last\:border-r-0:last-child {
  border-right-width: 0;
}

.has-\[\[data-focus-visible\]\]\:outline-2:has([data-focus-visible]) {
  outline-width: 2px;
}

.has-\[\[data-focus-visible\]\]\:outline-offset-1:has([data-focus-visible]) {
  outline-offset: 1px;
}

.has-\[\[data-focus-visible\]\]\:outline-theme-300:has([data-focus-visible]) {
  outline-color: #79cfca;
}

.placement-left\:slide-in-from-right-1[data-placement="left"] {
  --tw-enter-translate-x: .25rem;
}

.placement-left\:slide-out-to-right-1[data-placement="left"] {
  --tw-exit-translate-x: .25rem;
}

.placement-right\:slide-in-from-left-1[data-placement="right"] {
  --tw-enter-translate-x: -.25rem;
}

.placement-right\:slide-out-to-left-1[data-placement="right"] {
  --tw-exit-translate-x: -.25rem;
}

.placement-top\:slide-in-from-bottom-1[data-placement="top"] {
  --tw-enter-translate-y: .25rem;
}

.placement-top\:slide-out-to-bottom-1[data-placement="top"] {
  --tw-exit-translate-y: .25rem;
}

.placement-bottom\:slide-in-from-top-1[data-placement="bottom"] {
  --tw-enter-translate-y: -.25rem;
}

.placement-bottom\:slide-out-to-top-1[data-placement="bottom"] {
  --tw-exit-translate-y: -.25rem;
}

.group[data-orientation="horizontal"] .group-orientation-horizontal\:gap-4 {
  gap: 1rem;
}

.group[data-orientation="vertical"] .group-orientation-vertical\:flex-col {
  flex-direction: column;
}

.invalid\:bg-red-600:where([data-rac])[data-invalid], .invalid\:bg-red-600:where(:not([data-rac])):invalid {
  --tw-bg-opacity: 1;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity));
}

.focus-within\:outline-2:where([data-rac])[data-focus-within] {
  outline-width: 2px;
}

.focus-within\:outline-offset-1:where([data-rac])[data-focus-within] {
  outline-offset: 1px;
}

.focus-within\:outline-theme-300:where([data-rac])[data-focus-within] {
  outline-color: #79cfca;
}

.focus-within\:outline-2:where(:not([data-rac])):focus-within {
  outline-width: 2px;
}

.focus-within\:outline-offset-1:where(:not([data-rac])):focus-within {
  outline-offset: 1px;
}

.focus-within\:outline-theme-300:where(:not([data-rac])):focus-within {
  outline-color: #79cfca;
}

.hover\:border-slate-400:where([data-rac])[data-hovered] {
  --tw-border-opacity: 1;
  border-color: rgb(148 163 184 / var(--tw-border-opacity));
}

.hover\:border-theme-200:where([data-rac])[data-hovered] {
  --tw-border-opacity: 1;
  border-color: rgb(171 228 223 / var(--tw-border-opacity));
}

.hover\:bg-gray-100:where([data-rac])[data-hovered] {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.hover\:bg-gray-500\/20:where([data-rac])[data-hovered] {
  background-color: #6b728033;
}

.hover\:bg-orange-700:where([data-rac])[data-hovered] {
  --tw-bg-opacity: 1;
  background-color: rgb(194 65 12 / var(--tw-bg-opacity));
}

.hover\:bg-red-700:where([data-rac])[data-hovered] {
  --tw-bg-opacity: 1;
  background-color: rgb(185 28 28 / var(--tw-bg-opacity));
}

.hover\:bg-slate-200:where([data-rac])[data-hovered] {
  --tw-bg-opacity: 1;
  background-color: rgb(226 232 240 / var(--tw-bg-opacity));
}

.hover\:bg-slate-300:where([data-rac])[data-hovered] {
  --tw-bg-opacity: 1;
  background-color: rgb(203 213 225 / var(--tw-bg-opacity));
}

.hover\:bg-slate-500:where([data-rac])[data-hovered] {
  --tw-bg-opacity: 1;
  background-color: rgb(100 116 139 / var(--tw-bg-opacity));
}

.hover\:bg-theme-100:where([data-rac])[data-hovered] {
  --tw-bg-opacity: 1;
  background-color: rgb(213 242 239 / var(--tw-bg-opacity));
}

.hover\:bg-theme-200:where([data-rac])[data-hovered] {
  --tw-bg-opacity: 1;
  background-color: rgb(171 228 223 / var(--tw-bg-opacity));
}

.hover\:bg-theme-600:where([data-rac])[data-hovered] {
  --tw-bg-opacity: 1;
  background-color: rgb(39 120 122 / var(--tw-bg-opacity));
}

.hover\:text-theme-600:where([data-rac])[data-hovered] {
  --tw-text-opacity: 1;
  color: rgb(39 120 122 / var(--tw-text-opacity));
}

.hover\:border-slate-400:where(:not([data-rac])):hover {
  --tw-border-opacity: 1;
  border-color: rgb(148 163 184 / var(--tw-border-opacity));
}

.hover\:border-theme-200:where(:not([data-rac])):hover {
  --tw-border-opacity: 1;
  border-color: rgb(171 228 223 / var(--tw-border-opacity));
}

.hover\:bg-gray-100:where(:not([data-rac])):hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.hover\:bg-gray-500\/20:where(:not([data-rac])):hover {
  background-color: #6b728033;
}

.hover\:bg-orange-700:where(:not([data-rac])):hover {
  --tw-bg-opacity: 1;
  background-color: rgb(194 65 12 / var(--tw-bg-opacity));
}

.hover\:bg-red-700:where(:not([data-rac])):hover {
  --tw-bg-opacity: 1;
  background-color: rgb(185 28 28 / var(--tw-bg-opacity));
}

.hover\:bg-slate-200:where(:not([data-rac])):hover {
  --tw-bg-opacity: 1;
  background-color: rgb(226 232 240 / var(--tw-bg-opacity));
}

.hover\:bg-slate-300:where(:not([data-rac])):hover {
  --tw-bg-opacity: 1;
  background-color: rgb(203 213 225 / var(--tw-bg-opacity));
}

.hover\:bg-slate-500:where(:not([data-rac])):hover {
  --tw-bg-opacity: 1;
  background-color: rgb(100 116 139 / var(--tw-bg-opacity));
}

.hover\:bg-theme-100:where(:not([data-rac])):hover {
  --tw-bg-opacity: 1;
  background-color: rgb(213 242 239 / var(--tw-bg-opacity));
}

.hover\:bg-theme-200:where(:not([data-rac])):hover {
  --tw-bg-opacity: 1;
  background-color: rgb(171 228 223 / var(--tw-bg-opacity));
}

.hover\:bg-theme-600:where(:not([data-rac])):hover {
  --tw-bg-opacity: 1;
  background-color: rgb(39 120 122 / var(--tw-bg-opacity));
}

.hover\:text-theme-600:where(:not([data-rac])):hover {
  --tw-text-opacity: 1;
  color: rgb(39 120 122 / var(--tw-text-opacity));
}

.focus\:outline:where([data-rac])[data-focused] {
  outline-style: solid;
}

.focus\:outline-2:where([data-rac])[data-focused] {
  outline-width: 2px;
}

.focus\:outline-offset-1:where([data-rac])[data-focused] {
  outline-offset: 1px;
}

.focus\:outline-theme-300:where([data-rac])[data-focused] {
  outline-color: #79cfca;
}

.focus\:outline:where(:not([data-rac])):focus {
  outline-style: solid;
}

.focus\:outline-2:where(:not([data-rac])):focus {
  outline-width: 2px;
}

.focus\:outline-offset-1:where(:not([data-rac])):focus {
  outline-offset: 1px;
}

.focus\:outline-theme-300:where(:not([data-rac])):focus {
  outline-color: #79cfca;
}

.focus-visible\:bg-theme-100:where([data-rac])[data-focus-visible] {
  --tw-bg-opacity: 1;
  background-color: rgb(213 242 239 / var(--tw-bg-opacity));
}

.focus-visible\:outline:where([data-rac])[data-focus-visible] {
  outline-style: solid;
}

.focus-visible\:outline-0:where([data-rac])[data-focus-visible] {
  outline-width: 0;
}

.focus-visible\:outline-2:where([data-rac])[data-focus-visible] {
  outline-width: 2px;
}

.focus-visible\:outline-offset-1:where([data-rac])[data-focus-visible] {
  outline-offset: 1px;
}

.focus-visible\:outline-theme-300:where([data-rac])[data-focus-visible] {
  outline-color: #79cfca;
}

.focus-visible\:bg-theme-100:where(:not([data-rac])):focus-visible {
  --tw-bg-opacity: 1;
  background-color: rgb(213 242 239 / var(--tw-bg-opacity));
}

.focus-visible\:outline:where(:not([data-rac])):focus-visible {
  outline-style: solid;
}

.focus-visible\:outline-0:where(:not([data-rac])):focus-visible {
  outline-width: 0;
}

.focus-visible\:outline-2:where(:not([data-rac])):focus-visible {
  outline-width: 2px;
}

.focus-visible\:outline-offset-1:where(:not([data-rac])):focus-visible {
  outline-offset: 1px;
}

.focus-visible\:outline-theme-300:where(:not([data-rac])):focus-visible {
  outline-color: #79cfca;
}

.pressed\:bg-gray-500\/40[data-pressed] {
  background-color: #6b728066;
}

.pressed\:bg-orange-800[data-pressed] {
  --tw-bg-opacity: 1;
  background-color: rgb(154 52 18 / var(--tw-bg-opacity));
}

.pressed\:bg-red-900[data-pressed] {
  --tw-bg-opacity: 1;
  background-color: rgb(127 29 29 / var(--tw-bg-opacity));
}

.pressed\:bg-slate-400[data-pressed] {
  --tw-bg-opacity: 1;
  background-color: rgb(148 163 184 / var(--tw-bg-opacity));
}

.pressed\:bg-theme-200[data-pressed] {
  --tw-bg-opacity: 1;
  background-color: rgb(171 228 223 / var(--tw-bg-opacity));
}

.pressed\:bg-theme-300[data-pressed] {
  --tw-bg-opacity: 1;
  background-color: rgb(121 207 202 / var(--tw-bg-opacity));
}

.pressed\:bg-theme-800[data-pressed] {
  --tw-bg-opacity: 1;
  background-color: rgb(32 77 79 / var(--tw-bg-opacity));
}

.group[data-pressed] .group-pressed\:border-red-800 {
  --tw-border-opacity: 1;
  border-color: rgb(153 27 27 / var(--tw-border-opacity));
}

.group[data-pressed] .group-pressed\:border-slate-500 {
  --tw-border-opacity: 1;
  border-color: rgb(100 116 139 / var(--tw-border-opacity));
}

.group[data-pressed] .group-pressed\:border-slate-700 {
  --tw-border-opacity: 1;
  border-color: rgb(51 65 85 / var(--tw-border-opacity));
}

.group[data-pressed] .group-pressed\:border-theme-600 {
  --tw-border-opacity: 1;
  border-color: rgb(39 120 122 / var(--tw-border-opacity));
}

.selected\:border-theme-400[data-selected] {
  --tw-border-opacity: 1;
  border-color: rgb(77 180 178 / var(--tw-border-opacity));
}

.selected\:bg-theme-100[data-selected] {
  --tw-bg-opacity: 1;
  background-color: rgb(213 242 239 / var(--tw-bg-opacity));
}

.selected\:bg-theme-200[data-selected] {
  --tw-bg-opacity: 1;
  background-color: rgb(171 228 223 / var(--tw-bg-opacity));
}

.selected\:text-theme-400[data-selected] {
  --tw-text-opacity: 1;
  color: rgb(77 180 178 / var(--tw-text-opacity));
}

.selected\:text-theme-600[data-selected] {
  --tw-text-opacity: 1;
  color: rgb(39 120 122 / var(--tw-text-opacity));
}

.selected\:hover\:bg-theme-200:where([data-rac])[data-hovered][data-selected], .selected\:hover\:bg-theme-200:where(:not([data-rac])):hover[data-selected] {
  --tw-bg-opacity: 1;
  background-color: rgb(171 228 223 / var(--tw-bg-opacity));
}

.selected\:focus-visible\:bg-theme-300:where([data-rac])[data-focus-visible][data-selected], .selected\:focus-visible\:bg-theme-300:where(:not([data-rac])):focus-visible[data-selected] {
  --tw-bg-opacity: 1;
  background-color: rgb(121 207 202 / var(--tw-bg-opacity));
}

.group[data-selected] .group-selected\:font-semibold {
  font-weight: 600;
}

.disabled\:cursor-default:where([data-rac])[data-disabled] {
  cursor: default;
}

.disabled\:text-gray-300:where([data-rac])[data-disabled] {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}

.disabled\:text-slate-300:where([data-rac])[data-disabled] {
  --tw-text-opacity: 1;
  color: rgb(203 213 225 / var(--tw-text-opacity));
}

.disabled\:cursor-default:where(:not([data-rac])):disabled {
  cursor: default;
}

.disabled\:text-gray-300:where(:not([data-rac])):disabled {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}

.disabled\:text-slate-300:where(:not([data-rac])):disabled {
  --tw-text-opacity: 1;
  color: rgb(203 213 225 / var(--tw-text-opacity));
}

.group:where([data-rac])[data-disabled] .group-disabled\:text-gray-400, .group:where(:not([data-rac])):disabled .group-disabled\:text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

@media (min-width: 1024px) {
  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (min-width: 1280px) {
  .xl\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }
}

@media (prefers-color-scheme: dark) {
  .dark\:text-zinc-200 {
    --tw-text-opacity: 1;
    color: rgb(228 228 231 / var(--tw-text-opacity));
  }

  .dark\:text-zinc-600 {
    --tw-text-opacity: 1;
    color: rgb(82 82 91 / var(--tw-text-opacity));
  }

  .dark\:outline-blue-500 {
    outline-color: #3b82f6;
  }
}

@media (forced-colors: active) {
  .forced-colors\:outline-\[Highlight\] {
    outline-color: Highlight;
  }
}

/*# sourceMappingURL=index.ecca07a6.css.map */
