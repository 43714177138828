@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --theme-300: #abe4df;
  --theme-500: #349999;
}

.theme-scrollbar::-webkit-scrollbar {
  width: 12px;
}

.theme-scrollbar::-webkit-scrollbar-track {
  border-radius: 6px;
  background-color: #f4f4f4;
  border: 1px solid #eeeeee;
}

.theme-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 3px solid transparent;
  background-clip: content-box;
  background-color: var(--theme-300);
}
